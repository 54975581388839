<template>
  <div>
    <a-row class="container-detail" style="margin-top: 0">
      <p v-if="!dataHistory.record.length" class="info-text">
        Tidak ada riwayat
      </p>
      <a-col :span="24" v-for="item in dataHistory.record" :key="item.id">
        <a-card class="container-card" :bordered="true" :bodyStyle="{padding: '15px'}">
          <a-row>
            <a-col :span="15">
              <p class="title-detail">{{ item.prescription_number }}</p>
              <p class="text-detail">{{ item.updated_at | toDate }}</p>
            </a-col>
            <a-col :span="1" />
            <a-col align="end" :span="8">
              <a-button shape="round" type="default"
                style="border: solid 2px #0146ab;
                  color: #0146ab; font-weight: bold; font-size: 12px;"
                @click="showModal({ title: item.id, type: null })">
                Lihat Resep
              </a-button>
            </a-col>
          </a-row>
          <a-divider style="margin: 10px 0;"/>
          <a-row style="margin-bottom: 5px">
            <p class="title-item">Dokter</p>
            <p :span="24" class="text-item">{{ item.doctor_name || '-' }}</p>
          </a-row>
          <a-row>
            <p class="title-item">Diagnosis Banding</p>
            <p :span="24" class="text-item"
              v-for="(item, index) in formatDiagnosis(item.diagnosis)" :key="index">
              {{ item }}</p>
          </a-row>
        </a-card>
      </a-col>
      <a-col v-if="dataHistory.paginator.total > 5" :span="24" align="center" >
        <a-button shape="round" type="primary" @click="onLoadMore">
          Muat lebih banyak
        </a-button>
      </a-col>
    </a-row>
    <modal ref="form" :modal-title="modalTitle" />
  </div>
</template>

<script>
import Vue from "vue";
import { formatDate } from "@/helpers/utils";
import Modal from "../components/Modal.vue";

export default Vue.extend({
  components: {
    Modal,
  },
  props: {
    dataHistory: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      data: {},
      modalTitle: "",
    };
  },
  filters: {
    toDate(value) {
      return formatDate(value, "DD MMMM YYYY");
    },
  },
  methods: {
    formatDiagnosis(value) {
      if (!value) return "-";
      const diagnosis = value.substring(2, value.length - 2).split("\",\"");
      return diagnosis.map((item) => `${item.split(" ")[0]} - ${item.split(" ").slice(1).join(" ")}`);
    },
    showModal(param) {
      this.modalTitle = param.title;
      (this.$refs.form).showModal(param.type);
    },
    onLoadMore() {
      console.log("LoadMores");
    },
  },
});
</script>

<style lang="scss" scoped>
  .container-detail {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .container-card {
    border-radius: 15px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(76, 79, 84, 0.16);
  }
  p.title-detail {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    word-break: break-all;
    color: #0146ab;
    margin-bottom: 0;
  }
  p.text-detail {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0;
  }
  p.title-item {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    margin: 0;
  }
  p.text-item {
    font-size: 12px;
    line-height: 20px;
    margin: 0;
  }
</style>
