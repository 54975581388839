<template>
  <div>
    <a-row class="container-detail" style="margin-top: 0">
      <p class="notice-detail">Pastikan kembali daftar pesanan sudah sesuai dan
        siap diantar sebelum Anda memanggil kurir.</p>
    </a-row>
    <a-row class="container-detail">
      <list-item :data-item="dataOrder"/>
    </a-row>
    <a-row class="container-detail">
      <a-col :span="2">
        <a-checkbox @change="onChangeiAgree" />
      </a-col>
      <a-col :span="22">
        <p class="text-item">Pastikan kembali daftar pesanan sudah sesuai dan
          siap diantar sebelum Anda memanggil kurir.</p>
      </a-col>
    </a-row>
    <a-divider style="margin: 20px 0;"/>
    <a-row class="container-detail">
      <a-col :span="24">
        <a-card class="container-card" :bordered="true" :bodyStyle="{padding: '15px'}">
          <div slot="title"
            style="position: absolute; margin: 0px -10px;
              color: #0146ab; font-weight: bold; font-size: 14px; line-height: 20px;" >
            Informasi Penerima
          </div>
          <a-row>
            <a-col :span="24">
              <p class="title-item">Nama</p>
              <p class="text-item">{{ dataOrder.receiver.name }}</p>
            </a-col>
          </a-row>
          <a-divider style="margin: 10px 0;"/>
          <a-row>
            <a-col :span="24">
              <p class="title-item">Nomor Telepon</p>
              <p class="text-item">{{ dataOrder.receiver.phone }}</p>
            </a-col>
          </a-row>
          <a-divider style="margin: 10px 0;"/>
          <a-row>
            <a-col :span="24">
              <p class="title-item">Alamat Pengiriman</p>
              <p class="text-item">{{ dataOrder.receiver.address }}</p>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    <a-row class="container-detail">
      <a-col :span="24">
        <a-card class="container-card" :bordered="true" :bodyStyle="{padding: '15px'}">
          <div slot="title"
            style="position: absolute; margin: 0px -10px;
              color: #0146ab; font-weight: bold; font-size: 14px; line-height: 20px;" >
            Kiriman
          </div>
          <a-row>
            <a-col :span="24">
              <p class="title-item">Deskripsi</p>
              <p class="text-item">Pesanan Obat</p>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    <portal to="footer-right-sidebar">
      <a-row>
        <a-col :span="24" style="margin-top: 10px;">
          <a-button
            size="large"
            shape="round"
            style="width: 80%; font-weight: bold;"
            type="primary"
            :loading="loading"
            :disabled="!iAgree"
            @click="processOrder()">
            Cari Kurir
          </a-button>
        </a-col>
      </a-row>
    </portal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { Portal } from "portal-vue";
import { message } from "ant-design-vue";
import { formatNumberToRp } from "@/helpers/utils";
import ListItem from "../components/ListItem.vue";

export default Vue.extend({
  components: {
    Portal,
    ListItem,
  },
  props: {
    dataOrder: {
      type: Object,
    },
  },
  data() {
    return {
      patientId: "false",
      iAgree: false,
    };
  },
  computed: {
    ...mapGetters("FormStore", {
      loading: "getLoading",
    }),
  },
  filters: {
    toRp(value) {
      return formatNumberToRp(value);
    },
  },
  methods: {
    ...mapMutations("FormStore", {
      setLoading: "SET_LOADING",
    }),
    ...mapActions("OrderListStore", ["updateOrders"]),
    ...mapActions("OrderProsessStore", ["createPaxelDelivery"]),
    onChangeiAgree(e) {
      this.iAgree = e.target.checked;
    },
    processOrder() {
      this.setLoading(true);
      this.createPaxelDelivery({ order_id: this.dataOrder.id })
        .then(() => {
          this.updateOrders({ id: this.dataOrder.id });
          this.setLoading(false);
          message.success("Berhasil panggil kurir");
          this.$emit("handleOk");
        }).catch((error) => {
          console.log(error);
          this.setLoading(false);
          this.submitErrorMessage = "Gagal panggil kurir, mohon ulangi";
          this.isSubmitError = true;
        });
    },
  },
});
</script>

<style lang="scss" scoped>
  .container-detail {
    margin: 10px 0;
  }
  .container-card
    > div.ant-card-head{
      color: #0146ab;
    }
  .container-card {
    border-radius: 15px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(76, 79, 84, 0.16);
  }
  p.notice-detail {
    font-size: 16px;
    line-height: 20px;
    font-style: italic;
    color: #0146ab;
    margin-bottom: 5px;
  }
  p.title-detail {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    color: #0146ab;
    margin-bottom: 0;
  }
  p.text-detail {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0;
  }
  p.header-title-item{
    font-weight: bold;
    color: #0146ab;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
  p.title-item {
    color: #4c4f54;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
  p.text-item {
    color: #7f8184;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
</style>
