<template>
  <div style="height: 100%">
    <a-list-item class="header-list" slot="renderItem">
      <a-row :gutter="10">
        <a-col :span="6"><p class="header-title">Nama Pasien</p></a-col>
        <a-col :span="10"><p class="header-title">Alamat Pengiriman</p></a-col>
        <a-col :span="4"><p class="header-title">Total</p></a-col>
        <a-col :span="4"></a-col>
      </a-row>
    </a-list-item>
    <a-divider style="margin: 0;"/>
    <a-list class="content-list" header-listitem-layout="horizontal"
      :data-source="dataOrders" :loading="isLoading">
      <a-list-item :class="(item.id === getSelectedId.id) && 'selected'"
        slot="renderItem" slot-scope="item" @click="selected(item.id, item.patient_id)">
        <a-row :gutter="10">
          <a-col :span="6" >
            <p class="list-text-a">{{ item.patient_name }}</p>
            <p class="list-text-b">{{ item.prescription_number }}</p>
          </a-col>
          <a-col :span="10">
            <p class="list-text-c">
              {{ (item.bill_address) ? item.bill_address.address_location : "null" }}</p>
          </a-col>
          <a-col :span="4">
            <p class="list-text-d">{{ item.payment_total | toRp }}</p>
          </a-col>
          <a-col :span="4">
            <p class="list-text-e">{{ dateFromNow(item.updated_at) }}</p>
          </a-col>
        </a-row>
      </a-list-item>
      <div class="load-more"
        v-if="dataOrders.length !== getPaginatorInfo.total"
      >
        <a-spin v-if="loadingMore" />
        <a-button v-else shape="round" type="primary" @click="onLoadMore">
          Muat lebih banyak
        </a-button>
      </div>
    </a-list>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { formatNumberToRp } from "@/helpers/utils";

export default Vue.extend({
  props: {
    tabActive: {
      type: String,
    },
    isLoading: {
      type: Boolean,
    },
    loadingMore: {
      type: Boolean,
    },
    dataOrders: {
      type: Array,
    },
  },
  data() {
    return {
      loading: true,
      showLoadingMore: true,
      data: [],
    };
  },
  filters: {
    toRp(value) {
      return formatNumberToRp(value);
    },
  },
  computed: {
    ...mapGetters("OrderListStore", ["getPaginatorInfo", "getSelectedId"]),
  },
  methods: {
    ...mapActions("OrderListStore", ["updateSelectedList"]),
    selected(id, patientId) {
      this.$emit("selectedId", { id, patientId });
      this.updateSelectedList({ id, patientId });
    },
    dateFromNow(datetime) {
      const tmp = new Date(datetime);
      return moment(tmp - tmp.getTimezoneOffset() * 60 * 1000).fromNow(true);
    },
    onLoadMore() {
      this.$emit("fetchListOrderMores");
    },
  },
  created() {
    moment.updateLocale("en", {
      relativeTime: {
        s: "beberapa detik",
        ss: "%d detik",
        m: "Satu menit",
        mm: "%d menit",
        h: "Satu jam",
        hh: "%d jam",
        d: "Satu hari",
        dd: "%d hari",
        w: "Satu minggu",
        ww: "%d minggu",
        M: "Satu bulan",
        MM: "%d bulan",
        y: "Satu tahun",
        yy: "%d tahun",
      },
    });
  },
});
</script>

<style lang="scss">
  .header-title {
    font-size: 15px;
    font-weight: bold;
    margin: 0 0px 0 20px;
  }
  .content-list {
    overflow: auto;
    height: calc(100% - 100px);
  }
  .content-list
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-list-items
    > li
    > div {
      margin-left: 25px;
    }
  .content-list
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-list-items
    > .ant-list-item:hover {
      cursor: pointer;
      background-color: rgba(229, 229, 230, 0.24);
    }
  .content-list
    > .ant-spin-nested-loading
    > .ant-spin-container
    > .ant-list-items
    > .selected {
      background-color: rgba(229, 229, 230, 0.24);
      box-shadow: 5px 0px inset #0146ab;
      border-radius: 6px;
    }
  .content-list
   >.ant-spin-nested-loading{
      height: inherit;
   }
  .ant-list-item {
    display: block;
  }
  p.list-text-a {
    font-size: 15px;
    font-weight: bold;
    font-family: Roboto;
    line-height: 20px;
    color: #4c4f54;
    margin: 0 0px 0 20px;
  }
  p.list-text-b {
    font-size: 14px;
    font-family: Roboto;
    line-height: 20px;
    color: #7f8184;
    word-break: break-all;
    margin: 0 0px 0 20px;
  }
  p.list-text-c {
    font-size: 14px;
    font-family: Roboto;
    line-height: 20px;
    color: #7f8184;
    word-break: break-all;
    margin: 0 0px 0 20px;
  }
  p.list-text-d {
    font-size: 14px;
    font-weight: bold;
    font-family: Roboto;
    color: #4c4f54;
    margin: 0 0px 0 20px;
  }
  p.list-text-e {
    font-size: 14px;
    font-family: Roboto;
    color: #b2b3b5;
    margin: 0 0px 0 20px;
  }
  .load-more {
    margin-top: 10px;
    text-align: center;
    height: 32px;
    line-height: 32px;
  }
</style>
