<template>
  <div>
    <a-row class="container-detail" style="margin-top: 0">
      <list-item :data-item="dataOrder" :detail="true" />
    </a-row>
    <a-divider style="margin: 10px 0 15px 0;"/>
    <a-row class="container-detail">
      <a-col :span="24">
        <p class="title-detail">Alasan</p>
        <a-card class="container-card" :bordered="true" :bodyStyle="{padding: '15px'}">
          <a-row>
            <a-col :span="24">
              <p class="text-item">{{ dataOrder.rejection_reason }}</p>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import { formatNumberToRp } from "@/helpers/utils";
import ListItem from "../components/ListItem.vue";

export default Vue.extend({
  components: {
    ListItem,
  },
  props: {
    dataOrder: {
      type: Object,
    },
  },
  filters: {
    toRp(value) {
      return formatNumberToRp(value);
    },
  },
});
</script>

<style lang="scss" scoped>
  .container-detail {
    margin: 0;
  }
  .container-card {
    border-radius: 15px;
    margin: 10px 0;
    box-shadow: 0 4px 8px 0 rgba(76, 79, 84, 0.16);
  }
  p.title-detail {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    color: #4c4f54;
    margin-bottom: 0;
  }
  p.text-detail {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0;
  }
  p.title-item {
    color: #4c4f54;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
  p.text-item {
    color: #7f8184;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
</style>
