<template>
  <div>
    <div v-if="isLoadingList || isLoading"
      style="display: flex; justify-content: center; align-items: center; height: 100%;"
    >
      <a-spin :spinning="true">
        Menyiapkan Data
      </a-spin>
    </div>
    <div v-else-if="getOrders.length === 0"
      style="display: flex; justify-content: center; align-items: center; height: 100%;"
    >
      Tidak Ada Data
    </div>
    <div v-else-if="getSelectedId.id === null"
      style="display: flex; justify-content: center; align-items: center; height: 100%;"
    >
      Pilih Data Order
    </div>
    <div v-else-if="isDetail" style="height: inherit;">
      <a-card :bordered="false" :bodyStyle="infoSteps.footer
        ? {overflow: 'auto', height: 'calc(100% - 130px)'}
        : {overflow: 'auto', height: 'calc(100% - 70px)'}"
        :headStyle="{marginBottom: '0px'}"
        style="height: inherit;">
        <div slot="title">
          <a-row>
            <a-col :span="12" :style="{ marginTop: '5px' }">
              <span style="fontSize: 14px; fontWeight: bold; fontFamily: Roboto; color: #4c4f54">
                {{ infoSteps.title }}
              </span>
            </a-col>
            <a-col :span="12" align="end">
              <a-button v-for="(item, index) in steps" :key="index"
                :type="(index === step) ? 'primary' : 'default'"
                style="marginRight: 5px; width: 35px;" @click="updateStep(index)">
                <div class="icon-img">
                  <img :src="(step === index)
                    ? assetimages(item.icon[1]) : assetimages(item.icon[0])"
                  :alt="(step === index) ? item.icon[1] : item.icon[0]" />
                </div>
              </a-button>
            </a-col>
          </a-row>
        </div>
        <a-spin :spinning="isLoading">
          <template v-if="tabActive === 'recipe'">
            <recipe :step="step" />
          </template>
          <template v-else-if="tabActive === 'prosess'">
            <prosess :step="step" />
          </template>
          <template v-else-if="tabActive === 'delivery'">
            <delivery :step="step"/>
          </template>
          <template v-else-if="tabActive === 'finish'">
            <finish :step="step"/>
          </template>
          <template v-else-if="tabActive === 'rejected'">
            <rejected :step="step" />
          </template>
        </a-spin>
        <div v-if="infoSteps.footer" slot="actions" style="background-color: #fff">
          <portal-target class="portal-actions" name="footer-right-sidebar"></portal-target>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { PortalTarget } from "portal-vue";
import { helperIcon } from "@/helpers/commons";
import Recipe from "./recipe/index.vue";
import Prosess from "./prosess/index.vue";
import Delivery from "./delivery/index.vue";
import Finish from "./finish/index.vue";
import Rejected from "./rejected/index.vue";

export default Vue.extend({
  components: {
    PortalTarget,
    Recipe,
    Prosess,
    Delivery,
    Finish,
    Rejected,
  },
  props: {
    tabActive: {
      type: String,
    },
    isLoadingList: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: false,
      isDetail: false,
      constantSteps: {
        recipe: [
          {
            title: "Permintaan Obat",
            icon: ["asset8", "asset21"],
            footer: true,
          },
          {
            title: "Resep Obat",
            icon: ["asset9", "asset22"],
            footer: false,
          },
          {
            title: "Informasi Pasien",
            icon: ["asset10", "asset23"],
            footer: false,
          },
          {
            title: "Riwayat Tebus Resep",
            icon: ["asset11", "asset24"],
            footer: false,
          },
        ],
        prosess: [
          {
            title: "Pesanan Obat",
            icon: ["asset12", "asset25"],
            footer: true,
          },
          {
            title: "Invoice",
            icon: ["asset14", "asset27"],
            footer: false,
          },
        ],
        delivery: [
          {
            title: "Pengiriman Pesanan",
            icon: ["asset13", "asset26"],
            footer: false,
          },
          {
            title: "blank2",
            icon: ["asset13", "asset26"],
            footer: false,
          },
        ],
        finish: [
          {
            title: "Permintaan Obat",
            icon: ["asset8", "asset21"],
            footer: false,
          },
          {
            title: "Invoice",
            icon: ["asset14", "asset27"],
            footer: false,
          },
          {
            title: "Pengiriman Pesanan",
            icon: ["asset13", "asset26"],
            footer: false,
          },
        ],
        rejected: [
          {
            title: "Invoice",
            icon: ["asset14", "asset27"],
            footer: false,
          },
          {
            title: "Ditolak",
            icon: ["asset13", "asset26"],
            footer: false,
          },
        ],
      },
      step: 0,
    };
  },
  computed: {
    ...mapGetters("OrderListStore", ["getOrders", "getSelectedId"]),
    infoSteps() {
      return this.constantSteps[this.tabActive][this.step];
    },
    steps() {
      return this.constantSteps[this.tabActive];
    },
  },
  methods: {
    ...mapActions("OrderRecipeStore", { fetchOrderRecipe: "fetchOrder" }),
    ...mapActions("OrderProsessStore", { fetchOrderProsess: "fetchOrder" }),
    ...mapActions("OrderDeliveryStore", { fetchOrderDelivery: "fetchOrder" }),
    ...mapActions("OrderFinishStore", { fetchOrderFinish: "fetchOrder" }),
    ...mapActions("OrderRejectedStore", { fetchOrderRejected: "fetchOrder" }),
    ...mapActions("HistoryPrescriptionsStore", { fetchHistoryPrescriptions: "fetchHistoryPrescriptions" }),
    updateStep(param) {
      this.step = param;
    },
    assetimages(asset) {
      return helperIcon[asset];
    },
    loadData({ id, patientId }) {
      this.step = 0;
      this.isLoading = true;
      this.isDetail = false;
      switch (this.tabActive) {
        case "recipe":
          this.fetchOrderRecipe({ id })
            .then(() => {
              this.isLoading = false;
              this.isDetail = true;
            })
            .catch(() => {
              this.isLoading = false;
              this.isDetail = false;
            });
          this.fetchHistoryPrescriptions({ patientId, limit: 5 });
          break;
        case "prosess":
          this.fetchOrderProsess({ id })
            .then(() => {
              this.isLoading = false;
              this.isDetail = true;
            })
            .catch(() => {
              this.isLoading = false;
              this.isDetail = false;
            });
          break;
        case "delivery":
          this.fetchOrderDelivery({ id })
            .then(() => {
              this.isLoading = false;
              this.isDetail = true;
            })
            .catch(() => {
              this.isLoading = false;
              this.isDetail = false;
            });
          break;
        case "finish":
          this.fetchOrderFinish({ id })
            .then(() => {
              this.isLoading = false;
              this.isDetail = true;
            })
            .catch(() => {
              this.isLoading = false;
              this.isDetail = false;
            });
          break;
        case "rejected":
          this.fetchOrderRejected({ id })
            .then(() => {
              this.isLoading = false;
              this.isDetail = true;
            })
            .catch(() => {
              this.isLoading = false;
              this.isDetail = false;
            });
          break;
        default:
          console.log("no action");
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .portal-actions {
    margin: -12px 10px;
  }
  .icon-img {
    width: 33px;
    height: 30px;
    margin-left: -15px;
    text-align: center;
    line-height: 24px;
  }
  .icon-img img {
    height: auto;
    width: auto;
    margin-top: 8px;
    max-height: 18px;
    max-width: 18px;
  }
</style>
