<template>
  <div class="px-patient-info">
    <a-card :bordered="true" :bodyStyle="{padding: '0'}" :headStyle="{padding: '0'}">
      <div slot="title">
        Informasi Kesehatan
      </div>
      <a-row>
        <a-col :span="7">
          <p class="card-subtitle">Pasien</p>
          <p>{{ dataOrder.fullname }}</p>
        </a-col>
        <a-col :span="2">
          <div class="divider-vertical" style="height: 52px"/>
        </a-col>
        <a-col :span="8">
          <p class="card-subtitle">Jenis Kelamin</p>
          <p>{{ dataOrder.patient_gender }}</p>
        </a-col>
        <a-col :span="2">
          <div class="divider-vertical" style="height: 52px"/>
        </a-col>
        <a-col :span="5">
          <p class="card-subtitle">Umur</p>
          <p>{{ dataOrder.patient_age }}</p>
        </a-col>
      </a-row>
      <a-divider/>
      <a-row>
        <a-col :span="24">
          <p class="card-subtitle">Status Kesehatan & Kebiasaan</p>
        </a-col>
      </a-row>

      <a-row class="precondition-row">
        <p v-show="!precondition.length" class="info-text">
          Tidak ada
        </p>
        <a-col :span="12" v-for="item in precondition" :key="item.type"
          class="precondition">
          <a-col :span="4">
            <div :class="item.class">
              <img :src="item.url" :alt="item.type" />
            </div>
          </a-col>
          <a-col :span="20"><p>{{ preconditionName[item.type] }}</p></a-col>
        </a-col>
      </a-row>

      <a-divider />
      <a-row>
        <a-col :span="5">
          <p class="card-subtitle">Tinggi</p>
          <p>{{ dataOrder.patient_height }}</p>
        </a-col>
        <a-col :span="2">
          <div class="divider-vertical" style="height: 52px; margin-left: 10px;"/>
        </a-col>
        <a-col :span="4">
          <p class="card-subtitle">Berat</p>
          <p>{{ dataOrder.patient_weight }}</p>
        </a-col>
        <a-col :span="2">
          <div class="divider-vertical" style="height: 52px; margin-left: 10px;"/>
        </a-col>
        <a-col :span="11">
          <p class="card-subtitle">Indeks Masa Tubuh</p>
          <p>{{ dataOrder.patient_bmi_category }}</p>
        </a-col>
      </a-row>
    </a-card>

    <a-card :bordered="true" :bodyStyle="{padding: '0'}" :headStyle="{padding: '0'}">
      <div slot="title">
        Data Pribadi
      </div>
      <a-row>
        <a-col :span="24">
          <p class="card-subtitle">Tanggal Lahir</p>
          <p>{{ dataOrder.patient_dob | toDate}}</p>
        </a-col>
      </a-row>
      <a-divider />
      <a-row>
        <a-col :span="24">
          <p class="card-subtitle">Nomer Telepon</p>
          <p>{{ dataOrder.patient_phone }}</p>
        </a-col>
      </a-row>
      <a-divider />
      <a-row>
        <a-col :span="24">
          <p class="card-subtitle">Email</p>
          <p>{{ dataOrder.patient_email }}</p>
        </a-col>
      </a-row>
    </a-card>

    <a-card :bordered="true" :bodyStyle="{padding: '0'}" :headStyle="{padding: '0'}">
      <div slot="title">
        Data Asuransi
      </div>
      <a-row>
        <a-col :span="24">
          <p class="card-subtitle">Nama Asuransi</p>
          <p>{{ dataOrder.client_name }}</p>
        </a-col>
      </a-row>
      <a-divider />
      <a-row>
        <a-col :span="12">
          <p class="card-subtitle">Paket Layanan</p>
          <p>{{ dataOrder.patient_class }}</p>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import Vue from "vue";
import { formatDate } from "@/helpers/utils";
import { helperStatus } from "@/helpers/commons";

export default Vue.extend({
  props: {
    dataOrder: {
      type: Object,
    },
  },
  computed: {
    precondition() {
      const list = [];
      helperStatus.forEach((helper) => {
        if (this.dataOrder.precondition) {
          const checked = this.dataOrder.precondition[helper.type];
          list.push({
            ...helper,
            class: `precondition-img ${checked ? "primary" : ""}`,
          });
        } else {
          list.push({
            ...helper,
            class: "precondition-img",
          });
        }
      });
      return list.filter((val) => val.class === "precondition-img primary");
    },
  },
  data() {
    return {
      preconditionName: {
        hypertension: "Hipertensi",
        smoker: "Perokok",
        diabetes: "Diabetes",
        alcohol: "Minum Alkohol",
        heart_problems: "Gangguan Jantung",
        menopause: "Menopause",
        cancer: "Kanker",
        pregnant: "Hamil",
        kidney_failure: "Gagal Ginjal",
      },
    };
  },
  filters: {
    toDate(value) {
      return formatDate(value, "DD/MM/YYYY");
    },
  },
  methods: {
    testimage() {
      return helperStatus[1].url;
    },
  },
});
</script>

<style lang="scss" scoped>
  .precondition-row {
    display: flex;
    flex-wrap: wrap;
  }
  .precondition {
    display: flex;
  }
</style>
