<template>
  <div>
    <request v-show="step === 0" :data-order="requestOrder"/>
    <prescription v-show="step === 1" :data-order="prescriptionOrder"/>
    <patient-information v-show="step === 2" :data-order="patientInformationOrder"/>
    <prescription-history v-show="step === 3" :data-history="prescriptionHistoryOrder"/>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import Prescription from "./Prescription.vue";
import Request from "./Request.vue";
import PatientInformation from "./PatientInformation.vue";
import PrescriptionHistory from "./PrescriptionHistory.vue";

export default Vue.extend({
  components: {
    Prescription,
    Request,
    PatientInformation,
    PrescriptionHistory,
  },
  props: {
    step: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters("OrderRecipeStore", ["getOrder"]),
    ...mapGetters("HistoryPrescriptionsStore", ["getHistoryPrescriptions", "getPaginatorInfo"]),
    requestOrder() {
      return {
        id: this.getOrder.order.id,
        items: this.getOrder.variantStoreByOrderId.map((item) => ({
          id: item.variant.id,
          product_id: item.variantproduct_id,
          name: item.variant.name,
          sku: item.variant.sku,
          attributes: item.variant.attributes,
          patent: item.variant.attributes.find((o) => o.name === "patent").pivot.value,
          unit: item.variant.attributes.find((o) => o.name === "unit").pivot.value,
          amount: item.variant.attributes.find((o) => o.name === "amount").pivot.value,
          amount_unit: item.variant.attributes.find((o) => o.name === "amount_unit").pivot.value,
          stock: item.quantity,
          quantity: this.getOrder.order.line_items
            .find((o) => o.variant_id === item.variant.id).quantity,
          price: this.getOrder.order.line_items
            .find((o) => o.variant_id === item.variant.id).price,
        })),
      };
    },
    prescriptionOrder() {
      return {
        id: this.getOrder.order.id,
        client_supply_logo: this.getOrder.order.prescription.client_supply_logo,
        client_logo: this.getOrder.order.prescription.client_logo,
        prescription_number: this.getOrder.order.prescription.prescription_number,
        prescription_date: this.getOrder.order.prescription.created_at,
        doctor: {
          fullname: `dr. ${this.getOrder.order.prescription.doctor.doctor_f_name}
          ${this.getOrder.order.prescription.doctor.doctor_l_name}`,
          specialities: this.getOrder.order.prescription.doctor.specialities[0].speciality_name,
          doctor_sip: this.getOrder.order.prescription.doctor.doctor_sip,
          doctor_phone: this.getOrder.order.prescription.doctor.doctor_phone,
          doctor_signature: this.getOrder.order.prescription.doctor.doctor_signature,
        },
        hospital: {
          hospital_name: this.getOrder.order.prescription.doctor.hospital.hospital_name,
          hospital_address: this.getOrder.order.prescription.doctor.hospital.hospital_address,
        },
        patient: {
          fullname: `${this.getOrder.order.prescription.patient.patient_f_name}
          ${this.getOrder.order.prescription.patient.patient_l_name}`,
          patient_gender: (this.getOrder.order.prescription.patient_gender === "m") ? "Laki-Laki" : "Perempuan",
          patient_age: `${this.getOrder.order.prescription.patient_age} tahun`,
          patient_weight: `${this.getOrder.order.prescription.patient_weight} kg`,
        },
        differential_diagnosis: this.getOrder.order.prescription.differential_diagnosis
          .substring(2, this.getOrder.order.prescription.differential_diagnosis.length - 2)
          .split("\",\""),
        allergy: this.getOrder.order.prescription.allergy,
        medicine_prescriptions: this.getOrder.order.prescription.medicine_prescriptions
          .map((item) => ({
            brand: item.medicine.brand,
            frequency: `${item.frequency}x${item.frequency_dd}, ${item.amount_unit} per hari, ${item.timing}`,
            duration: `${item.duration} ${item.duration_unit}`,
            amount: `${item.amount} ${item.amount_unit}`,
            substitution: (item.substitution === 1) ? "Ada" : "Tidak Ada",
            notes: item.notes,
          })),
      };
    },
    patientInformationOrder() {
      return {
        id: this.getOrder.order.id,
        fullname: `${this.getOrder.order.prescription.patient.patient_f_name}
        ${this.getOrder.order.prescription.patient.patient_l_name}`,
        patient_gender: this.getOrder.order.prescription.patient_gender === "m" ? "Laki-Laki" : "Perempuan",
        patient_age: `${this.getOrder.order.prescription.patient.patient_age} Tahun`,
        patient_height: `${this.getOrder.order.prescription.patient.patient_height || "-"} cm`,
        patient_weight: `${this.getOrder.order.prescription.patient.patient_weight || "-"} kg`,
        patient_bmi_category: this.getOrder.order.prescription.patient.patient_bmi_category || "-",
        precondition: {
          alcohol: this.getOrder.order.prescription.patient.precondition.alcohol,
          cancer: this.getOrder.order.prescription.patient.precondition.cancer,
          diabetes: this.getOrder.order.prescription.patient.precondition.diabetes,
          heart_problems: this.getOrder.order.prescription.patient.precondition.heart_problems,
          hypertension: this.getOrder.order.prescription.patient.precondition.hypertension,
          kidney_failure: this.getOrder.order.prescription.patient.precondition.kidney_failure,
          menopause: this.getOrder.order.prescription.patient.precondition.menopause,
          pregnant: this.getOrder.order.prescription.patient.precondition.pregnant,
          smoker: this.getOrder.order.prescription.patient.precondition.smoker,
        },
        patient_dob: this.getOrder.order.prescription.patient.patient_dob,
        patient_phone: this.getOrder.order.prescription.patient.patient_phone || "-",
        patient_email: this.getOrder.order.prescription.patient.patient_email || "-",
        client_name: this.getOrder.order.prescription.patient.client_name || "-",
        patient_class: this.getOrder.order.prescription.patient.patient_class || "-",
      };
    },
    prescriptionHistoryOrder() {
      return {
        record: this.getHistoryPrescriptions || [],
        paginator: this.getPaginatorInfo,
      };
    },
  },

});
</script>
