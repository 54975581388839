<template>
  <div>
    <a-row class="container-detail" style="margin-top: 0">
      <a-col :span="12">
        <img height="28" :src="dataOrder.client_supply_logo" />
      </a-col>
      <a-col :span="12" align="end">
        <img height="28" :src="dataOrder.client_logo" />
      </a-col>
    </a-row>
    <a-row class="container-detail">
      <a-col :span="12">
        <h3 style="fontWeight: bold; color: #0146ab">RESEP OBAT</h3>
      </a-col>
      <a-col :span="12" align="end">
        <p class="title-detail-sub">{{ dataOrder.prescription_number }}</p>
        <p class="text-detail">
          {{ dataOrder.hospital.hospital_address | getCityHospital }},
          {{ dataOrder.prescription_date | toDate }}
        </p>
      </a-col>
    </a-row>
    <a-row class="container-detail">
      <a-col :span="12">
        <p class="title-detail-sub">{{ dataOrder.doctor.fullname }}</p>
        <p class="text-detail">{{ dataOrder.doctor.specialities }}</p>
        <p class="text-detail">{{ dataOrder.doctor.doctor_sip }}</p>
        <p class="text-detail">{{ dataOrder.doctor.doctor_phone }}</p>
      </a-col>
      <a-col :span="12" align="end">
        <p class="title-detail-sub">{{ dataOrder.hospital.hospital_name }}</p>
        <p class="text-detail">{{ dataOrder.hospital.hospital_address }}</p>
      </a-col>
    </a-row>
    <a-divider style="margin: 10px 0;"/>
    <a-row class="container-detail">
      <a-col :span="24">
        <p class="title-detail">Pasien</p>
        <p class="title-detail-sub">{{ dataOrder.patient.fullname }}</p>
        <p class="text-detail">
          {{ dataOrder.patient.patient_gender }},
          {{ dataOrder.patient.patient_age }} ,
          {{ dataOrder.patient.patient_weight }}
        </p>
      </a-col>
    </a-row>
    <a-row class="container-detail">
      <a-col :span="24">
        <p class="title-detail">Diagnosis Banding</p>
        <!-- <p class="text-detail">{{ dataOrder.differential_diagnosis }}</p> -->
        <div v-for="(item, index) in dataOrder.differential_diagnosis" :key="index">
          <a-col :span="1">
            <p class="text-detail">{{ index+1 }}.</p>
          </a-col>
          <a-col :span="23">
            <p class="text-detail">{{ item }}</p>
          </a-col>
        </div>
      </a-col>
    </a-row>
    <a-row class="container-detail">
      <a-col :span="24">
        <p class="title-detail">Alergi</p>
        <p class="text-detail">{{ dataOrder.allergy }}</p>
      </a-col>
    </a-row>
    <a-row class="container-detail">
      <a-col :span="24" v-for="item in dataOrder.medicine_prescriptions" :key="item.id">
        <a-card :bordered="true" :bodyStyle="{padding: '15px'}"
          style="borderRadius: 10px; margin-bottom: 20px;">
          <p class="header-title-item">{{ item.brand }}</p>
          <a-divider style="margin: 10px 0;"/>
          <a-row :gutter="50">
            <a-col :span="6"><p class="title-item">Frekuensi</p></a-col>
            <a-col :span="18">
              <p class="text-item">{{ item.frequency }}</p>
            </a-col>
          </a-row>
          <a-row :gutter="50">
            <a-col :span="6"><p class="title-item">Durasi</p></a-col>
            <a-col :span="16">
              <p class="text-item">{{ item.duration }}</p>
            </a-col>
          </a-row>
          <a-row :gutter="50">
            <a-col :span="6"><p class="title-item">Jumlah</p></a-col>
            <a-col :span="18">
              <p class="text-item">{{ item.amount }}</p>
            </a-col>
          </a-row>
          <a-row :gutter="50">
            <a-col :span="6"><p class="title-item">Substitusi</p></a-col>
            <a-col :span="16">
              <p class="text-item">{{ item.substitution }}</p>
            </a-col>
          </a-row>
          <a-divider style="margin: 10px 0;"/>
          <a-row :gutter="50">
            <a-col :span="6"><p class="title-item">Catatan</p></a-col>
            <a-col :span="16"><p class="text-item">{{ item.notes }}</p></a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    <a-row class="container-detail">
      <a-col :span="24">
        <p class="title-detail">Catatan Resep</p>
        <div v-for="(item, index) in notes" :key="index">
          <a-col :span="2">
            <p class="text-detail">{{ index+1 }}.</p>
          </a-col>
          <a-col :span="22">
            <p class="text-detail">{{ item }}</p>
          </a-col>
        </div>
      </a-col>
    </a-row>
    <a-row class="container-detail">
      <a-col :span="24" align="center">
        <div style="width: 60%;">
          <img height="100%" width="100%" :src="dataOrder.doctor.doctor_signature" />
          <a-divider style="margin: 10px 0;"/>
          <span>{{ dataOrder.doctor.fullname }}</span>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import { formatDate } from "@/helpers/utils";

export default Vue.extend({
  props: {
    dataOrder: {
      type: Object,
    },
  },
  data() {
    return {
      notes: [
        "Subtitusi berarti obat dapat digantikan dengan merek lain selama kandungan dan kekutannya sama",
        "Obat yang diresepkan hanya untuk penggunaan sesuai instruksi dokter",
        "Penyalahgunaan obat bukan tanggung jawab Pihak Asuransi maupun Dockter",
      ],
    };
  },
  filters: {
    toDate(value) {
      return formatDate(value, "DD MMMM YYYY");
    },
    getCityHospital(value) {
      const city = value.split(" ");
      return city[city.length - 1];
    },
  },
});
</script>

<style lang="scss" scoped>
  .container-detail {
    margin: 15px 0;
  }
  p.title-detail {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    color: #4c4f54;
    margin-bottom: 0;
  }
  p.title-detail-sub {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    word-break: break-all;
    color: #4c4f54;
    margin-bottom: 0;
  }
  p.text-detail {
    color: #7f8184;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0;
  }
  p.header-title-item{
    font-weight: bold;
    color: #0146ab;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
  p.title-item {
    font-weight: bold;
    color: #4c4f54;
    font-size: 12px;
    line-height: 20px;
    margin: 0;
  }
  p.text-item {
    color: #7f8184;
    font-size: 12px;
    line-height: 20px;
    margin: 0;
  }
</style>
