<template>
  <div>
    <delivery v-show="step === 0" :data-order="orderDelivery" :realTime="true" />
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import Delivery from "../components/Delivery.vue";

export default Vue.extend({
  components: {
    Delivery,
  },
  props: {
    step: {
      type: Number,
    },
  },
  data() {
    return {
      condition: 1,
    };
  },
  computed: {
    ...mapGetters("OrderDeliveryStore", ["getOrder", "getOrderHistory"]),
    orderDelivery() {
      return {
        id: this.getOrder.id,
        driver_name: this.getOrder.delivery.driver_name,
        phone: "-",
        number: this.getOrder.delivery.number,
        tracking: this.getOrder.delivery.tracking,
        state: this.getOrder.delivery.state,
        delivery_status: {
          is_waiting_for_picked_up: this.getOrder.history.filter((o) => o.state === "ORDER_IS_WAITING_FOR_PICKED_UP"),
          is_picked_by_courier: this.getOrder.history.filter((o) => o.state === "ORDER_IS_PICKED_BY_COURIER"),
          item_on_the_way: this.getOrder.history.filter((o) => o.state === "ITEM_ON_THE_WAY_TO_CUSTOMER"),
          item_delivered: this.getOrder.history.filter((o) => o.state === "ITEM_DELIVERED"),
        },
        test: this.getOrder.history,
      };
    },
  },

});
</script>
