<template>
  <div>
    <a-row class="container-detail" style="margin-top: 0">
      <list-item :data-item="dataOrder" :detail="true" />
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import { formatNumberToRp } from "@/helpers/utils";
import ListItem from "../components/ListItem.vue";

export default Vue.extend({
  components: {
    ListItem,
  },
  props: {
    dataOrder: {
      type: Object,
    },
  },
  filters: {
    toRp(value) {
      return formatNumberToRp(value);
    },
  },
});
</script>

<style lang="scss" scoped>
  .container-detail {
    margin: 0;
  }
</style>
