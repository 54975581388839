var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"layout":_vm.formLayout,"form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[(_vm.isSubmitError)?_c('div',{staticClass:"margin-baseB"},[_c('a-alert',{attrs:{"type":"error","message":_vm.submitErrorMessage,"banner":""}})],1):_vm._e(),_c('a-form-item',{attrs:{"validate-status":_vm.checkError('argumen') ? 'error' : '',"help":_vm.checkError('argumen') || ''}},[_c('template',{slot:"label"},[_c('div',[_vm._v(" Alasan ")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'argumen',
            { rules: [{ required: true, message: 'Pilih Alasan' }] } ]),expression:"[\n          'argumen',\n            { rules: [{ required: true, message: 'Pilih Alasan' }] },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"size":"large","show-search":"","placeholder":"Pilih Alasan","option-filter-prop":"children"},on:{"change":_vm.handleChange}},[_c('a-select-option',{attrs:{"value":"Stok obat habis"}},[_vm._v(" Stok obat habis ")]),_c('a-select-option',{attrs:{"value":"Apotek sedang tidak dapat melayani"}},[_vm._v(" Apotek sedang tidak dapat melayani ")]),_c('a-select-option',{attrs:{"value":"Lainnya"}},[_vm._v(" Lainnya ")])],1)],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.otherArgumenStatus),expression:"otherArgumenStatus"}]},[_c('p',[_vm._v("Tuliskan alasan penolakan tebus resep di bawah dan beri keterangan sedetail mungkin.")]),_c('a-form-item',{attrs:{"label":"Tulis Alasan","validate-status":_vm.checkError('otherArgumen') ? 'error' : '',"help":_vm.checkError('otherArgumen') || ''}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'otherArgumen', {
              rules: [
                {
                  required: _vm.form.getFieldValue('argumen') === 'Lainnya',
                  message: 'Alasan tidak boleh kosong',
                }
              ]
            } ]),expression:"[\n            'otherArgumen', {\n              rules: [\n                {\n                  required: form.getFieldValue('argumen') === 'Lainnya',\n                  message: 'Alasan tidak boleh kosong',\n                }\n              ]\n            },\n          ]"}],attrs:{"size":"large","placeholder":"","type":"textarea","rows":"4"}})],1)],1),_c('div',{staticClass:"p-modal-button-container"},[_c('a-button',{staticClass:"p-modal-cancel",attrs:{"size":"large"},on:{"click":_vm.handleCancel}},[_vm._v(" Batal ")]),_c('a-button',{staticClass:"p-modal-submit",attrs:{"type":"danger","size":"large","html-type":"submit","disabled":_vm.hasErrors(_vm.form.getFieldsError()),"loading":_vm.isLoading}},[_vm._v(" Tolak ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }