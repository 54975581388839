<template>
  <div>
    <a-row class="container-detail">
      <a-col :span="24">
        <a-card class="container-card" :bordered="true" :bodyStyle="{padding: '15px'}">
          <div slot="title"
            style="position: absolute; margin: 0px -10px;
              color: #0146ab; font-weight: bold; font-size: 14px; line-height: 20px;" >
            Informasi Kurir
          </div>
          <a-row>
            <a-col :span="24">
              <p class="title-item">Nama</p>
              <p class="text-item">{{ dataOrder.driver_name }}</p>
            </a-col>
          </a-row>
          <a-divider style="margin: 10px 0;"/>
          <a-row>
            <a-col :span="24">
              <p class="title-item">Nomor Telepon</p>
              <p class="text-item">{{ dataOrder.phone }}</p>
            </a-col>
          </a-row>
          <a-divider style="margin: 10px 0;"/>
          <a-row>
            <a-col :span="24">
              <p class="title-item">Plat Kendaraan</p>
              <p class="text-item">{{ dataOrder.number }}</p>
            </a-col>
          </a-row>
          <a-divider style="margin: 10px 0;"/>
          <a-row>
            <a-col :span="24">
              <p class="title-item">Kode Pengiriman</p>
              <p class="text-item">{{ dataOrder.tracking }}</p>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    <a-row class="container-detail">
      <a-col :span="24">
        <a-card class="container-card" :bordered="true" :bodyStyle="{padding: '15px'}">
          <div slot="title"
            style="position: absolute; margin: 0px -10px;
              color: #0146ab; font-weight: bold; font-size: 14px; line-height: 20px;" >
            Status Pengantaran
          </div>
          <a-row v-for="(item, index) in history.dataStatus" :key="index">
            <a-col :span="4" align="center">
              <div class="icon-status-img">
                <img :src="(state >= item.state)
                  ? loadIcon(item.asset[0]) : loadIcon(item.asset[1])"
                :alt="(state >= item.state) ? item.asset[0] : item.asset[1]" />
              </div>
            </a-col>
            <a-col :span="20">
              <p :class="(state >= item.state) ? 'title-item' : 'title-item-deactivate'">
                {{ item.title }}</p>
              <p v-if="state >= item.state" class="text-item">
                {{item.record ? item.record.updated_at : null | toDate }}</p>
              <a-divider v-if="index < 3" style="margin: 10px 0;"/>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import { helperIcon } from "@/helpers/commons";
import { formatDate } from "@/helpers/utils";
import PusherConnection from "@/helpers/pusherConfig";

export default Vue.extend({
  props: {
    dataOrder: {
      type: Object,
    },
    realTime: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    state() {
      const states = ["RTP", "RAP", "COL", "PAPV", "PRJL", "POD", "COD", "PDO", "UNDLM", "RTN"];
      return states.indexOf(this.dataOrder.state);
    },
    history() {
      const dataStatus = [
        {
          title: "Menunggu kurir",
          state: 0,
          record: this.getLastData(this.dataOrder.delivery_status.is_waiting_for_picked_up),
          asset: ["asset28", "asset32"],
        },
        {
          title: "Pesanan diambil kurir",
          state: 3,
          record: this.getLastData(this.dataOrder.delivery_status.is_picked_by_courier),
          asset: ["asset29", "asset33"],
        },
        {
          title: "Pesanan obat dikirim",
          state: 5,
          record: this.getLastData(this.dataOrder.delivery_status.item_on_the_way),
          asset: ["asset30", "asset34"],
        },
        {
          title: "Pesanan obat selesai",
          state: 7,
          record: this.getLastData(this.dataOrder.delivery_status.item_delivered),
          asset: ["asset31", "asset35"],
        },
      ];
      const step = dataStatus.indexOf(undefined) - 1;
      return { dataStatus, step };
    },
  },
  filters: {
    toDate(value) {
      return formatDate(value, "DD MMMM, HH:mm");
    },
  },
  methods: {
    loadIcon(asset) {
      return helperIcon[asset];
    },
    getLastData(data) {
      return data.length ? data[data.length - 1] : null;
    },
  },
  mounted() {
    if (this.realTime) {
      this.$store
        .dispatch("OrderDeliveryStore/subscribeOrderHistory", {
          store_id: this.$route.params.id,
          order_id: this.dataOrder.id,
        })
        .then((resp) => {
          resp.subscribe((event) => {
            window.pusher = new PusherConnection(
              event.extensions.lighthouse_subscriptions.channel,
              this.$store,
            );
          });
        });
    }
  },
  beforeDestroy() {
    if (this.realTime) window.pusher.disconnect();
  },
});
</script>

<style lang="scss" scoped>
  .container-detail {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .container-card
    > div.ant-card-head{
      color: #0146ab;
    }
  .container-card {
    border-radius: 15px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(76, 79, 84, 0.16);
  }
  p.title-detail {
    color: #4c4f54;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    margin-bottom: 0;
  }
  p.text-detail {
    color: #7f8184;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }
  p.header-title-item{
    font-weight: bold;
    color: #0146ab;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
  p.title-item {
    color: #4c4f54;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
  p.text-item {
    color: #7f8184;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
  .icon-status-img {
    width: 40px;
    height: 40px;
    margin: 0px 8px 4px 0;
    text-align: center;
    border-radius: 50%;
    background: rgba(229, 229, 230, 0.16);
    line-height: 24px;
  }
  .icon-status-img img {
    height: auto;
    width: auto;
    margin-top: 10px;
    max-height: 20px;
    max-width: 20px;
  }
  .title-item-deactivate {
    color: #b2b3b5;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;
  }
</style>
