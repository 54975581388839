<template>
  <div>
    <a-row class="container-detail" style="margin-top: 0">
      <a-col :span="12">
        <img height="28" :src="dataOrder.client_supply_logo" />
      </a-col>
      <a-col :span="12" align="end">
        <img height="28" :src="dataOrder.client_logo" />
      </a-col>
    </a-row>
    <a-row class="container-detail">
      <a-col :span="10">
        <p style="font-size: 18px; fontWeight: bold; color: #0146ab">INVOICE</p>
      </a-col>
      <a-col :span="14" align="end">
        <p class="title-detail">{{ dataOrder.invoice.number }}</p>
        <p class="text-detail">{{ dataOrder.invoice.created_at | toDate }}</p>
      </a-col>
    </a-row>
    <a-row class="container-detail">
      <a-col :span="10">
        <p class="title-detail">{{ dataOrder.patient_name }}</p>
        <p class="text-detail">Pembayaran :</p>
        <p class="text-detail">{{ dataOrder.invoice.payment_method.type | titleCase }}</p>
      </a-col>
      <a-col :span="14" align="end">
        <p class="title-detail">{{ dataOrder.store_name }}</p>
        <p class="text-detail">{{ dataOrder.store_address }}</p>
      </a-col>
    </a-row>
    <a-divider style="margin: 10px 0;"/>
    <a-row class="container-detail">
      <p class="title-detail" style="margin-bottom: 5px">No. Resep</p>
      <p class="title-detail-sub">{{ dataOrder.prescription_number }}</p>
    </a-row>
    <a-row class="container-detail">
      <p class="title-detail" style="margin-bottom: 5px">Daftar Obat</p>
      <a-card :bordered="true" :bodyStyle="{padding: '15px'}"
        style="borderRadius: 10px; margin-bottom: 10px;">
        <div v-for="(item, index) in dataOrder.items" :key="index" style="margin-bottom: 5px">
          <p class="header-title-item">{{ item.varian }}</p>
          <p class="title-item">
            {{ item.medicine_reference_name }} {{ item.amount }} {{ item.amount_unit }}
          </p>
          <a-row>
            <a-col :span="12">
              <p class="text-detail">{{ item.price | toRp }} x {{ item.quantity }}</p>
            </a-col>
            <a-col :span="12" align="end">
              <p class="title-item">{{ item.sub_total | toRp }}</p>
            </a-col>
          </a-row>
        </div>
        <a-divider style="margin: 10px 0;"/>
        <a-row>
          <a-col :span="12"><p class="title-item" >Biaya Obat</p></a-col>
          <a-col :span="12" align="end">
            <p class="title-item">{{ dataOrder.item_total | toRp }}</p>
          </a-col>
        </a-row>
      </a-card>
    </a-row>
    <a-divider style="margin: 10px 0 15px 0;"/>
    <a-row>
      <p class="title-detail" style="margin-bottom: 5px">Informasi Pengiriman</p>
      <a-card :bordered="true" :bodyStyle="{padding: '15px'}"
        style="borderRadius: 10px; margin-bottom: 10px;">
        <p class="title-item">Rumah</p>
        <p class="text-item">{{ dataOrder.delivery_info.phone }}</p>
        <p class="text-item">{{ dataOrder.delivery_info.address }}</p>
        <p class="text-item">
          <font-awesome-icon icon="file-alt" class="margin-microR" />
          {{ dataOrder.delivery_info.address_detail }}
          </p>
      </a-card>
      <a-card :bordered="true" :bodyStyle="{padding: '15px'}"
        style="borderRadius: 10px; margin-bottom: 10px;">
        <p class="title-item">Paxel Same Day Delivery</p>
        <a-row>
          <a-col :span="14">
            <p class="text-item">Estimasi tiba hari ini</p>
          </a-col>
          <a-col :span="10" align="end" class="title-item">
            <p class="title-item">{{ dataOrder.delivery_total | toRp }}</p>
          </a-col>
        </a-row>
      </a-card>
    </a-row>
    <a-divider style="margin: 10px 0;"/>
    <a-row class="container-detail">
      <a-card :bordered="true" :bodyStyle="{padding: '15px'}"
        style="borderRadius: 10px; margin-bottom: 20px;">
        <a-row>
          <a-col :span="15">
            <p class="title-item">Metode Pembayaran</p>
          </a-col>
          <a-col align="end" :span="8">
            <p class="title-item" style="color: #0146ab">
              {{ dataOrder.invoice.payment_method.name | titleCase }}</p>
          </a-col>
        </a-row>
        <a-divider style="margin: 10px 0;"/>
        <a-row>
          <a-row>
            <a-col :span="12">
              <p class="text-item">Biaya Obat</p>
            </a-col>
            <a-col :span="12" align="end">
              <p class="title-item">{{ dataOrder.item_total | toRp }}</p>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <p class="text-item" style="color: #0146ab">Tanggungan Asuransi</p>
            </a-col>
            <a-col :span="12" align="end">
              <p class="title-item" style="color: #0146ab">
                - {{ dataOrder.discounts.value_in_idr | toRp }}</p>
            </a-col>
          </a-row>
        </a-row>
        <a-divider style="margin: 10px 0;"/>
        <a-row>
          <a-row>
            <a-col :span="12">
              <p class="text-item">Total Biaya Tebus Resep</p>
            </a-col>
            <a-col :span="12" align="end">
              <p class="title-item">
                {{ dataOrder.item_total - dataOrder.discounts.value_in_idr | toRp }}</p>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <p class="text-item">Biaya Kirim</p>
            </a-col>
            <a-col :span="12" align="end">
              <p class="title-item">{{ dataOrder.delivery_total | toRp }}</p>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <p class="text-item">Biaya Admin</p>
            </a-col>
            <a-col :span="12" align="end">
              <p class="title-item">{{ dataOrder.admin_fee | toRp }}</p>
            </a-col>
          </a-row>
        </a-row>
        <a-divider style="margin: 10px 0;"/>
        <a-row>
          <a-col align="start" :span="12" style="color: green; font-weight: bold;">
            Total Pembayaran
          </a-col>
          <a-col align="end" :span="12" style="color: green; font-weight: bold;">
            {{ dataOrder.payment_total | toRp }}
          </a-col>
        </a-row>
      </a-card>
    </a-row>
    <a-row class="container-detail">
      <a-card :bordered="true" :bodyStyle="{padding: '10px'}"
        style="borderRadius: 10px;">
        <a-col align="start" :span="8" style="color: #0146ab; font-weight: bold;">
          Prixa
        </a-col>
        <a-col align="end" :span="16" style="font-weight: bold;">
          {{ dataOrder.invoice.number }}
        </a-col>
      </a-card>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import {
  formatDate,
  formatNumberToRp,
} from "@/helpers/utils";

export default Vue.extend({
  props: {
    dataOrder: {
      type: Object,
    },
  },
  filters: {
    toRp(value) {
      return formatNumberToRp(value);
    },
    toDate(value) {
      return formatDate(value, "DD MMMM YYYY");
    },
    titleCase(str) {
      const splitStr = str.toLowerCase().split("-");
      for (let i = 0; i < splitStr.length; i += 1) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(" ");
    },
  },
  methods: {
    handleCancel() {
      this.$emit("handleCancel");
    },
  },
});
</script>

<style lang="scss" scoped>
  .container-detail {
    margin: 15px 0;
  }
  p.title-detail {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    color: #4c4f54;
    margin: 0;
  }
  p.title-detail-sub {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    color: #4c4f54;
    margin-bottom: 0;
  }
  p.text-detail {
    color: #7f8184;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0;
  }
  p.header-title-item{
    font-weight: bold;
    color: #0146ab;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
  p.title-item {
    font-weight: bold;
    color: #4c4f54;
    font-size: 12px;
    line-height: 20px;
    margin: 0;
  }
  p.text-item {
    color: #7f8184;
    font-size: 12px;
    line-height: 20px;
    margin: 0;
  }
</style>
