<template>
  <div>
    <order v-show="step === 0" :data-order="order"/>
    <invoice v-show="step === 1" :data-order="invoiceOrder"/>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import Order from "./Order.vue";
import Invoice from "../components/Invoice.vue";

export default Vue.extend({
  components: {
    Order,
    Invoice,
  },
  props: {
    step: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters("OrderProsessStore", ["getOrder"]),
    order() {
      return {
        id: this.getOrder.order.id,
        items: this.getOrder.variantStoreByOrderId.map((item) => ({
          id: item.variant.id,
          product_id: item.variantproduct_id,
          name: item.variant.name,
          sku: item.variant.sku,
          attributes: item.variant.attributes,
          patent: item.variant.attributes.find((o) => o.name === "patent").pivot.value,
          unit: item.variant.attributes.find((o) => o.name === "unit").pivot.value,
          amount: item.variant.attributes.find((o) => o.name === "amount").pivot.value,
          amount_unit: item.variant.attributes.find((o) => o.name === "amount_unit").pivot.value,
          quantity: this.getOrder.order.line_items
            .find((o) => o.variant_id === item.variant.id).quantity,
          price: this.getOrder.order.line_items
            .find((o) => o.variant_id === item.variant.id).price,
        })),
        receiver: {
          name: this.getOrder.order.patient_name,
          phone: this.getOrder.order.bill_address.phone,
          address: this.getOrder.order.bill_address.address_location,
        },
      };
    },
    invoiceOrder() {
      return {
        client_supply_logo: this.getOrder.order.prescription.client_supply_logo,
        client_logo: this.getOrder.order.prescription.client_logo,
        invoice: this.getOrder.order.payments.find((obj) => obj.state === "SUCCESS"),
        patient_name: this.getOrder.order.patient_name,
        store_name: this.getOrder.order.store.name,
        store_address: this.getOrder.order.store.address,
        prescription_number: this.getOrder.order.prescription.prescription_number,
        items: this.getOrder.order.line_items.map((item) => ({
          varian: item.variant.name,
          medicine_reference_name: this.getOrder.variantStoreByOrderId
            .find((o) => o.variant.id === item.variant_id).variant.medicine_reference_name,
          patent: item.variant.attributes.find((o) => o.name === "patent").pivot.value,
          unit: item.variant.attributes.find((o) => o.name === "unit").pivot.value,
          amount: item.variant.attributes.find((o) => o.name === "amount").pivot.value,
          amount_unit: item.variant.attributes.find((o) => o.name === "amount_unit").pivot.value,
          price: item.price,
          quantity: item.quantity,
          sub_total: item.price * item.quantity,
        })),
        delivery_info: {
          phone: this.getOrder.order.bill_address.phone,
          address: this.getOrder.order.bill_address.address_location,
          address_detail: this.getOrder.order.bill_address.address_detail,
        },
        item_total: this.getOrder.order.item_total,
        discounts: {
          percentage: this.getOrder.order.discounts[0].percentage,
          value_in_idr: this.getOrder.order.discounts[0].value_in_idr,
        },
        delivery_total: this.getOrder.order.delivery_total,
        admin_fee: this.getOrder.order.admin_fee,
        payment_total: this.getOrder.order.payment_total,
      };
    },
  },

});
</script>
