<template>
  <div>
    <a-col :span="24" v-for="item in dataItem.items" :key="item.id">
      <a-card class="container-card" :bordered="true" :bodyStyle="{padding: '15px'}">
        <a-row>
          <a-col :span="detail ? 15 : 24">
            <p class="title-detail">{{ item.name }}</p>
            <p class="text-detail">{{ item.unit }}, {{ item.amount }} {{ item.amount_unit }}</p>
          </a-col>
          <a-col v-if="detail" align="end" :span="8">
            <a-button
              shape="round"
              type="default"
              style="border: solid 2px #0146ab; color: #0146ab; font-weight: bold;"
              @click="showModal({ title: `${item.name} - ${item.id}`, type: null })"
            >
              Lihat Detail
            </a-button>
          </a-col>
        </a-row>
        <a-divider style="margin: 10px 0;"/>
        <a-row>
          <a-col :span="15">
            <a-row>
              <a-col :span="7"><p class="title-item">SKU</p></a-col>
              <a-col :span="17"><p class="text-item">{{ item.sku }}</p></a-col>
            </a-row>
          </a-col>
          <a-col :span="9">
            <a-row>
              <a-col :span="17" class="title-item"><p class="title-item">Permintaan</p></a-col>
              <a-col :span="7" align="end" class="text-item">
                <p class="text-item">{{ item.quantity }}</p>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="15">
            <a-row>
              <a-col :span="7" class="title-item"><p class="title-item">Harga</p></a-col>
              <a-col :span="17" class="text-item">
                <p class="text-item">{{ item.price | toRp }}</p>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-card>
    </a-col>
    <modal ref="form" :modal-title="modalTitle" />
  </div>
</template>

<script>
import Vue from "vue";
import { formatNumberToRp } from "@/helpers/utils";
import Modal from "./Modal.vue";

export default Vue.extend({
  components: {
    Modal,
  },
  props: {
    dataItem: {
      type: Object,
    },
    detail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalTitle: "",
    };
  },
  filters: {
    toRp(value) {
      return formatNumberToRp(value);
    },
  },
  methods: {
    showModal(param) {
      this.modalTitle = param.title;
      (this.$refs.form).showModal(param);
    },
  },
});
</script>

<style lang="scss" scoped>
  .container-card {
    border-radius: 15px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px 0 rgba(76, 79, 84, 0.16);
  }
  p.title-detail {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    color: #0146ab;
    margin-bottom: 0;
  }
  p.text-detail {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0;
  }
  p.title-item {
    color: #4c4f54;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
  p.text-item {
    color: #7f8184;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
</style>
