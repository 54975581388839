<template>
  <div class="p-pharmacy-delivery-wrapper">
    <portal to="header-toolbar">
      <a-select v-model="dayFilter" default-value="today" style="width: 150px"
        @change="handleChange">
        <a-select-option value="today">
          Hari Ini
        </a-select-option>
        <a-select-option value="yesterday">
          Kemarin
        </a-select-option>
        <a-select-option value="this_week">
          Minggu Ini
        </a-select-option>
      </a-select>
    </portal>
    <portal to="right-sidebar-content">
      <template>
        <detail-order ref="detail" :tabActive="tabActive" :id="getSelectedId"
          :is-loading-list="isLoading" style="height: inherit;"/>
      </template>
    </portal>
    <div class="p-pharmacy-delivery">
      <a-tabs :default-active-key="tabActive" @tabClick="tabClick">
        <a-tab-pane v-for="(item) in tabs" :key="item.key" :disabled="isLoading">
          <span slot="tab">
            {{ item.name }}
            <a-badge
              :count="(getCountOrders[item.key])
                ? getCountOrders[item.key].paginatorInfo.total : 0"
              :overflow-count="10"
              show-zero
              :number-style="{
                backgroundColor: 'rgba(229, 229, 230, 0.4)',
                color: '#7f8184',
                fontWeight: 'bold',
                fontSize: '14px',
              }"
            />
          </span>
          <template>
            <list-order ref="list" :tabActive="tabActive" :data-orders="getOrders"
              :isLoading="isLoading" :loadingMore="loadingMore"
              @selectedId="selectedId" @fetchListOrderMores="fetchListOrderMores" />
          </template>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { Portal } from "portal-vue";
import { message } from "ant-design-vue";
import ListOrder from "@/components/pharmacyDelivery/ListOrder.vue";
import DetailOrder from "@/components/pharmacyDelivery/DetailOrder.vue";

export default Vue.extend({
  components: {
    Portal,
    ListOrder,
    DetailOrder,
  },
  data() {
    return {
      isLoading: false,
      loadingMore: false,
      tabs: [
        {
          key: "recipe",
          name: "Resep Baru",
          notif: 11,
          state: "CUSTOMER_PAYMENT_SUCCESS",
        },
        {
          key: "prosess",
          name: "Diproses",
          notif: 0,
          state: "ORDER_IS_PROCESSED_BY_PHARMACY",
        },
        {
          key: "delivery",
          name: "Dikirim",
          notif: 0,
          state: [
            "ORDER_IS_WAITING_FOR_PICKED_UP",
            "RE_ATTEMPT_PICKUP",
            "COURIER_ARRIVED_AT_PICKUP_LOCATION",
            "ORDER_IS_PICKED_BY_COURIER",
            "ITEM_ON_THE_WAY_TO_CUSTOMER",
            "COURIER_ARRIVED_AT_DESTINATION",
          ],
        },
        {
          key: "finish",
          name: "Selesai",
          notif: 0,
          state: "ITEM_DELIVERED",
        },
        {
          key: "rejected",
          name: "Ditolak",
          notif: 0,
          state: [
            "CUSTOMER_REFUND_PROCESSED",
            "ORDER_DECLINED_BY_PHARMACY",
          ],
        },
      ],
      tabActive: "recipe",
      dayFilter: "today",
      stateFilter: "CUSTOMER_PAYMENT_SUCCESS",
      limit: 10,
    };
  },
  computed: {
    ...mapGetters("OrderListStore", ["getOrders", "getCountOrders", "getSelectedId"]),
  },
  methods: {
    ...mapActions("OrderListStore", ["fetchOrders", "fetchOrdersCount", "updateSelectedList", "emptyOrders"]),
    handleChange() {
      this.fetchListOrders();
    },
    tabClick(val) {
      this.tabActive = val;
      const index = this.tabs.findIndex((tab) => tab.key === val);
      this.stateFilter = this.tabs[index].state;
      this.fetchListOrders();
    },
    selectedId(id, patientId) {
      (this.$refs.detail).loadData(id, patientId);
    },
    fetchListOrders() {
      this.isLoading = true;
      this.emptyOrders();
      this.updateSelectedList({ id: null, patientId: null });
      this.fetchOrdersCount({
        store_id: this.$route.params.id,
        relative_date: this.dayFilter,
      });
      this.fetchOrders({
        store_id: this.$route.params.id,
        state: this.stateFilter,
        relative_date: this.dayFilter,
        limit: this.limit,
        offset: 0,
      })
        .then(() => {
          this.isLoading = false;
          this.updateSelectedList({
            id: this.getOrders[0].id,
            patientId: this.getOrders[0].patient_id,
          });
          (this.$refs.detail).loadData(this.getSelectedId);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    fetchListOrderMores() {
      this.loadingMore = true;
      this.fetchOrders({
        store_id: this.$route.params.id,
        state: this.stateFilter,
        relative_date: this.dayFilter,
        limit: this.limit,
        offset: this.getOrders.length,
      })
        .then((res) => {
          if (res.data.length === 0) message.info("Tidak ada data yang ditampilkan lagi");
          this.loadingMore = false;
        })
        .catch(() => {
          this.loadingMore = false;
        });
    },
  },
  mounted() {
    this.fetchListOrders();
  },
  created() {
    this.$emit("setRightSidebarVisible", true);
  },
  watch: {
    $route() {
      this.fetchListOrders();
    },
  },
});
</script>

<style lang="scss">
  #style {
    height: 800px;
  }
  main.ant-layout-content{
    overflow: hidden;
  }
  .ant-scroll-number-only > p.ant-scroll-number-only-unit {
    color: #7f8184;
    font-size: 14px;
    font-weight: bold;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #0148ab;
    font-weight: bold;
    .ant-badge {
      .ant-scroll-number {
        color: #0146ab !important;
      }
    }
    .ant-scroll-number-only > p.ant-scroll-number-only-unit {
      color: #0146ab !important;
    }
  }
  .p-pharmacy-delivery-wrapper {
    height: inherit;
    .p-pharmacy-delivery {
      height: inherit;
      .ant-tabs {
        height: inherit;
      }
      .ant-tabs-content {
        height: inherit;
      }
    }
  }
</style>
