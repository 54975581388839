<template>
  <div>
    <a-row class="container-detail" style="margin-top: 0">
      <a-col :span="24" v-for="item in dataOrder.items" :key="item.id">
        <a-card class="container-card" :bordered="true" :bodyStyle="{padding: '15px'}">
          <a-row>
            <a-col :span="15">
              <p class="title-detail">{{ item.name }}</p>
              <p class="text-detail">{{ item.unit }}, {{ item.amount }} {{ item.amount_unit }}</p>
            </a-col>
            <a-col align="end" :span="8">
              <a-button
                shape="round"
                type="default"
                style="border: solid 2px #0146ab; color: #0146ab; font-weight: bold;"
                @click="showModal({ title: `${item.name} - ${item.id}`, type: null })"
              >
                Lihat Detail
              </a-button>
            </a-col>
          </a-row>
          <a-divider style="margin: 10px 0;"/>
          <a-row>
            <a-col :span="15">
              <a-row>
                <a-col :span="7"><p class="title-item">SKU</p></a-col>
                <a-col :span="17"><p class="text-item">{{ item.sku }}</p></a-col>
              </a-row>
            </a-col>
            <a-col :span="9">
              <a-row>
                <a-col :span="17" class="title-item"><p class="title-item">Stok</p></a-col>
                <a-col :span="7" align="end" class="text-item">
                  <p class="text-item">{{ item.stock }}</p>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="15">
              <a-row>
                <a-col :span="7" class="title-item"><p class="title-item">Harga</p></a-col>
                <a-col :span="17" class="text-item">
                  <p class="text-item">{{ item.price | toRp }}</p>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="9">
              <a-row>
                <a-col :span="17" class="title-item"><p class="title-item">Permintaan</p></a-col>
                <a-col :span="7" align="end" class="text-item">
                  <p class="text-item">{{ item.quantity }}</p>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    <portal to="footer-right-sidebar">
      <a-row :gutter="15">
        <a-col :span="8" style="margin-top: 10px;">
          <a-button
            size="large"
            type="default"
            shape="round"
            style="width: 100%; border: solid 2px #ff6e5d; color: #ff6e5d; font-weight: bold;"
            @click="showModal({ title: 'Alasan Penolakan', type: 'void', id: dataOrder.id })"
          >
            Tolak
          </a-button>
        </a-col>
        <a-col :span="16" style="margin-top: 10px;">
          <a-button
            size="large"
            align="end"
            shape="round"
            style="width: 100%; font-weight: bold;"
            type="primary"
            :loading="loading"
            @click="processOrder()">
            Terima & Proses
          </a-button>
        </a-col>
      </a-row>
    </portal>
    <modal ref="form" :modal-title="modalTitle" />
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { Portal } from "portal-vue";
import { message } from "ant-design-vue";
import { formatNumberToRp } from "@/helpers/utils";
import Modal from "../components/Modal.vue";

export default Vue.extend({
  components: {
    Portal,
    Modal,
  },
  props: {
    dataOrder: {
      type: Object,
    },
  },
  data() {
    return {
      modalTitle: "",
    };
  },
  computed: {
    ...mapGetters("FormStore", {
      loading: "getLoading",
    }),
  },
  filters: {
    toRp(value) {
      return formatNumberToRp(value);
    },
  },
  methods: {
    ...mapMutations("FormStore", {
      setLoading: "SET_LOADING",
    }),
    ...mapActions("OrderListStore", ["updateOrders"]),
    ...mapActions("OrderRecipeStore", ["processOrDeclineOrder"]),
    showModal(param) {
      this.modalTitle = param.title;
      (this.$refs.form).showModal(param);
    },
    onChange(e) {
      console.log(`checked = ${e.target.checked}`);
    },
    processOrder() {
      this.setLoading(true);
      const dataInput = {
        is_order_accepted: true,
        shipping_method_id: JSON.parse(localStorage.getItem("shipping")) || null,
      };
      this.processOrDeclineOrder({ order_id: this.dataOrder.id, input: dataInput })
        .then(() => {
          this.updateOrders({ id: this.dataOrder.id });
          this.setLoading(false);
          message.success("Berhasil Terima Pesanan");
          this.$emit("handleOk");
        }).catch((error) => {
          console.log(error);
          this.setLoading(false);
          message.error("Gagal Terima Pesanan, mohon diulangi");
          this.isSubmitError = true;
        });
    },
  },
});
</script>

<style lang="scss" scoped>
  .container-detail {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .container-card {
    border-radius: 15px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(76, 79, 84, 0.16);
  }
  p.title-detail {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    color: #0146ab;
    margin-bottom: 0;
  }
  p.text-detail {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0;
  }
  p.title-item {
    color: #4c4f54;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
  p.text-item {
    color: #7f8184;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }
</style>
