<template>
  <div>
    <a-form
      :layout="formLayout"
      :form="form"
      @submit="handleSubmit"
      :hideRequiredMark="true"
    >
      <div class="margin-baseB" v-if="isSubmitError">
        <a-alert type="error" :message="submitErrorMessage" banner />
      </div>
      <a-form-item
        :validate-status="checkError('argumen') ? 'error' : ''"
        :help="checkError('argumen') || ''"
      >
        <template slot="label">
          <div>
            Alasan
          </div>
        </template>
        <a-select size="large" show-search placeholder="Pilih Alasan"
          option-filter-prop="children" style="width: 100%" @change="handleChange"
          v-decorator="[
            'argumen',
              { rules: [{ required: true, message: 'Pilih Alasan' }] },
          ]"
        >
          <a-select-option value="Stok obat habis">
            Stok obat habis
          </a-select-option>
          <a-select-option value="Apotek sedang tidak dapat melayani">
            Apotek sedang tidak dapat melayani
          </a-select-option>
          <a-select-option value="Lainnya">
            Lainnya
          </a-select-option>
        </a-select>
      </a-form-item>
      <div v-show="otherArgumenStatus">
        <p>Tuliskan alasan penolakan tebus resep di bawah dan beri keterangan sedetail mungkin.</p>
        <a-form-item
          label="Tulis Alasan"
          :validate-status="checkError('otherArgumen') ? 'error' : ''"
          :help="checkError('otherArgumen') || ''"
        >
          <a-input
            size="large"
            placeholder=""
            type="textarea"
            rows="4"
            v-decorator="[
              'otherArgumen', {
                rules: [
                  {
                    required: form.getFieldValue('argumen') === 'Lainnya',
                    message: 'Alasan tidak boleh kosong',
                  }
                ]
              },
            ]"
          />
        </a-form-item>
      </div>
      <div class="p-modal-button-container">
        <a-button
          class="p-modal-cancel"
          size="large"
          @click="handleCancel"
        >
          Batal
        </a-button>
        <a-button
          type="danger"
          size="large"
          html-type="submit"
          :disabled="hasErrors(form.getFieldsError())"
          :loading="isLoading"
          class="p-modal-submit"
        >
          Tolak
        </a-button>
      </div>
    </a-form>
  </div>
</template>

<script>
import Vue from "vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { message } from "ant-design-vue";

export default Vue.extend({
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      isLoading: false,
      formLayout: "vertical",
      isSubmitError: false,
      submitErrorMessage: "Gagal menambah admin, mohon ulangi",
      otherArgumenStatus: false,
    };
  },
  computed: {
    ...mapGetters("FormStore", {
      hasErrors: "getErrors",
      form: "getForm",
      loading: "getLoading",
    }),
  },
  methods: {
    ...mapMutations("FormStore", {
      setForm: "SET_FORM",
      validateFirst: "VALIDATE_FIRST",
      setLoading: "SET_LOADING",
    }),
    ...mapActions("OrderListStore", ["updateOrders"]),
    ...mapActions("OrderRecipeStore", ["processOrDeclineOrder"]),
    handleCancel() {
      this.$emit("handleCancel");
    },
    checkError(field) {
      const { getFieldError, isFieldTouched } = this.form;
      return isFieldTouched(field) && getFieldError(field);
    },
    handleChange(value) {
      this.otherArgumenStatus = value === "Lainnya";
    },
    handleSubmit(e) {
      e.preventDefault();
      this.isSubmitError = false;
      this.form.validateFields((err, values) => {
        this.isLoading = true;
        const dataInput = {
          is_order_accepted: false,
          rejection_reason: (values.argumen !== "Lainnya") ? values.argumen : values.otherArgumen,
        };
        this.processOrDeclineOrder({ order_id: this.id, input: dataInput })
          .then(() => {
            this.updateOrders({ id: this.id });
            this.isLoading = false;
            message.success("Berhasil Tolak Pesanan");
            this.$emit("handleOk");
          }).catch((error) => {
            console.log(error);
            this.isLoading = false;
            this.submitErrorMessage = "Gagal Tolak Pesanan, mohon diulangi";
            this.isSubmitError = true;
          });
      });
    },
  },
  created() {
    this.setForm(this.$form.createForm(this, { name: "void_form" }));
  },
  mounted() {
    this.validateFirst(this);
  },
});
</script>
