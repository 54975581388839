<template>
  <a-modal v-model="visible" @ok="handleOk"
    :footer="false" :closable="false" :bodyStyle="{maxHeight: '700px'}">
    <template v-if="modalTitle" slot="title">
      <div>
        <span
          class="p-modal-header-title"
        >{{ modalTitle }}</span>
      </div>
    </template>
    <template v-if="visibleContent === 'void'">
      <form-void @handleOk="handleOk" @handleCancel="handleCancel" :id="recordId" />
    </template>
    <!-- <template v-else-if="visibleContent === 'preview'">
      <preview @handleOk="handleOk" @handleCancel="handleCancel" />
    </template> -->
  </a-modal>
</template>

<script>
import Vue from "vue";
import FormVoid from "./FormVoid.vue";

export default Vue.extend({
  components: {
    FormVoid,
  },
  props: {
    modalTitle: {
      type: String,
    },
  },
  data() {
    return {
      visible: false,
      visibleContent: "",
      recordId: "",
    };
  },
  methods: {
    showModal(record) {
      this.visible = true;
      this.visibleContent = record.type;
      this.recordId = record.id;
    },
    handleOk() {
      this.visible = false;
    },
    handleCancel() {
      this.visible = false;
    },
  },
});
</script>
